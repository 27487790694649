import { useParams } from "react-router-dom"
import { getCollectionByName } from "../Data/Collections"
import Header from "../Components/Header"
import Content from "../Components/Mint/Content"
import "../Assets/Styles/Mint.scss"

function Mint() {
	let { collectionName } = useParams()

	let collection = getCollectionByName(collectionName)

	return (
		<>
			<Header/>
			<Content collection={ collection }/>
		</>
	)
}

export default Mint