import { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { BigNumber, ethers } from "ethers"
import moment from "moment"
import { isDevBuild } from "../../Utilities/Build"
import SuggestedWallets from "./SuggestedWallets"
import ConnectDialog from "./ConnectDialog"
import CountdownDialog from "./CountdownDialog"
import MintDialog from "./MintDialog"
import EndedDialog from "./EndedDialog"

function MainGroup(props) {
	const Dialog = {
		connect: 0,
		whitelistCountdown: 1,
		publicCountdown: 2,
		whitelistMint: 3,
		publicMint: 4,
		ended: 5
	}

	let [ isInitializing, setIsInitializing ] = useState(false)
	let [ isInitialized, setIsInitialized ] = useState(false)
	let [ isConnecting, setIsConnecting ] = useState(false)
	let [ isConnected, setIsConnected ] = useState(false)
	let [ isWhitelisted, setIsWhitelisted ] = useState(false)
	let [ provider, setProvider ] = useState()
	let [ address, setAddress ] = useState()
	let [ contract, setContract ] = useState()
	let [ dialog, setDialog ] = useState(Dialog.connect)

	useEffect(function () {
		(async function () {
			if (isInitializing || isInitialized) return

			setIsInitializing(true)

			if (props.collection === undefined) {
				props.onError("Collection doesn't exist.", null)
				return
			}

			if (window.ethereum === undefined) {
				if (isMobile) {
					props.onError((
						"Ethereum wallet not detected!\n" +
						"Open this page with your wallet's browser."
					), null)
				} else {
					props.onError((
						"Ethereum wallet not installed!\n" +
						"Our suggested wallets: "
					), <SuggestedWallets/>)
				}

				return
			}

			function reloadPage() {
				window.location.reload()
			}

			window.ethereum.on("disconnect", reloadPage)
			window.ethereum.on("chainsChanged", reloadPage)
			window.ethereum.on("accountsChanged", reloadPage)

			setProvider(new ethers.providers.Web3Provider(window.ethereum))

			setIsInitialized(true)
			setIsInitializing(false)
		})()
	}, [])

	const ChainIds = {
		mainnet: "0x1",
		ropsten: "0x3",
		rinkeby: "0x4",
		goerli: "0x5",
		kovan: "0x2a"
	}

	async function connect() {
		if (isConnecting || isConnected) return

		setIsConnecting(true)

		let chainId = await window.ethereum.request({
			method: "eth_chainId"
		})

		if (isDevBuild()) {
			if (chainId !== ChainIds.rinkeby) {
				props.onError((
					"You are on wrong network.\n" +
					"Please switch to Ethereum Rinkeby."
				), null)

				return
			}
		} else {
			if (chainId !== ChainIds.mainnet) {
				props.onError((
					"You are on wrong network.\n" +
					"Please switch to Ethereum Mainnet."
				), null)

				return
			}
		}

		try {
			let [ account ] = await window.ethereum.request({
				method: "eth_requestAccounts"
			})

			setAddress(account)

			let contractAddress = isDevBuild() ?
				props.collection.devContractAddress :
				props.collection.prodContractAddress

			setContract(new ethers.Contract(contractAddress, props.collection.contractAbi, provider.getSigner()))

			setIsConnected(true)
		} catch (error) {
			props.onError(error.message)
		}

		setIsConnecting(false)
	}

	useEffect(function () {
		(async function () {
			if (!isConnected) return

			setIsWhitelisted(await contract.whitelistUserAllowance(address))
		})()
	}, [ isConnected ])

	useEffect(function () {
		(async function () {
			if (!isInitialized) return

			async function canReconnect(provider) {
				let accounts = await provider.listAccounts()
				return accounts > 0
			}

			if (await canReconnect(provider)) {
				await connect()
			}
		})()
	}, [ isInitialized ])

	useEffect(function () {
		function updateDialog() {
			if (!isConnected) return

			let isWhitelistPeriod = moment.utc().isBetween(
				props.collection.whitelistPeriod.begin,
				props.collection.whitelistPeriod.end
			)

			let isPublicPeriod = moment.utc().isBetween(
				props.collection.publicPeriod.begin,
				props.collection.publicPeriod.end
			)

			let isEnded = moment.utc().isAfter(props.collection.publicPeriod.end)

			if (isConnected) {
				if (isEnded) {
					setDialog(Dialog.ended)
				} else {
					if (isWhitelistPeriod) {
						if (isWhitelisted) {
							setDialog(Dialog.whitelistMint)
						} else {
							setDialog(Dialog.publicCountdown)
						}
					} else if (isPublicPeriod) {
						setDialog(Dialog.publicMint)
					} else {
						setDialog(Dialog.whitelistCountdown)
					}
				}
			} else {
				setDialog(Dialog.connect)
			}
		}

		let timer = setInterval(updateDialog, 1000)

		updateDialog()

		return function () {
			clearInterval(timer)
		}
	}, [ isInitialized, isConnected, isWhitelisted ])

	function shortenAddress(address) {
		if (address.length === 42) {
			let begin = address.slice(0, 7)
			let end = address.slice(-5)
			return begin + "..." + end
		}

		return address
	}

	const Status = {
		disabled: 0,
		whitelist: 1,
		public: 2
	}

	async function mintCount() {
		let mintCont = await contract.mintCount(address)
		return mintCont.toNumber()
	}

	async function balance() {
		let balance = await provider.getBalance(address)
		return parseFloat(ethers.utils.formatEther(balance))
	}

	async function mint(quantity, isWhitelist) {
		let status = await contract.mintingStatus()

		if (isWhitelist) {
			if (status !== Status.whitelist) {
				props.onError("Whitelist minting is disabled.")
				return
			}

			if (!isWhitelisted) {
				props.onError("You have not been whitelisted.")
				return
			}
		} else {
			if (status !== Status.public) {
				props.onError("Public minting is disabled.")
				return
			}
		}

		let count = await mintCount()

		if (count === props.collection.limit) {
			props.onError("You have already minted maximum amount.")
			return
		} else if (count + quantity > props.collection.limit) {
			let remaining = props.collection.limit - count

			props.onError("You only have " + remaining + " mints left.")
			return
		}

		let price = quantity * props.collection.price

		if (price > await balance()) {
			props.onError("Insufficient balance for minting.")
			return
		}

		try {
			let value = ethers.utils.parseEther(price.toFixed(2))

			if (isWhitelist) {
				await contract.whitelistMint(BigNumber.from(quantity), { value: value })
			} else {
				await contract.publicMint(BigNumber.from(quantity), { value: value })
			}

			if (quantity === 1) {
				props.onSuccess((
					"Your NFT has been minted.\n" +
					"Wait for transaction to complete."
				))
			} else {
				props.onSuccess((
					"Your NFTs have been minted.\n" +
					"Wait for transaction to complete."
				))
			}
		} catch (error) {
			let message = "Unknown error detected."

			if (
				error.error !== undefined &&
				error.error.message !== undefined
			) {
				message = error.error.message
			} else if (error.message !== undefined) {
				message = error.message
			}

			if (message.includes(":")) {
				message = message.split(":")[1]
			}

			message = message.trim()
			message = message.charAt(0).toUpperCase() + message.slice(1)

			if (!message.endsWith(".")) {
				message += "."
			}

			props.onError(message)
		}
	}

	async function whitelistMint(quantity) {
		await mint(quantity, true)
	}

	async function publicMint(quantity) {
		await mint(quantity, false)
	}

	useEffect(function () {
		if (isDevBuild()) {
			if (window.debug !== undefined) return

			let timeFormat = "YYYY-MM-DD HH:mm"

			console.log(
				"Development build detected. Injecting debug functions!\n" +
				"\n" +
				"debug.currentTime() - Get current time\n" +
				"debug.setWhitelistPeriodBegin(time) - Set whitelist mint period begin time\n" +
				"debug.setWhitelistPeriodEnd(time) - Set whitelist mint period end time\n" +
				"debug.setPublicPeriodBegin(time) - Set public mint period begin time\n" +
				"debug.setPublicPeriodEnd(time) - Set public mint period end time\n" +
				"debug.setAutomaticPeriodsWithOffset(offset) - Set automatic mint period times with offset in seconds\n" +
				"\n" +
				"*Time format: " + timeFormat
			)

			window.debug = {}

			window.debug.currentTime = function () {
				console.log("Current time: " + moment().utc().format(timeFormat))
			}

			window.debug.setWhitelistPeriodBegin = function (time) {
				props.collection.whitelistPeriod.begin = moment.utc(time)

				console.log("Whitelist mint period begin set to: " + time.format("YYYY-MM-DD HH:mm:ss"))
			}

			window.debug.setWhitelistPeriodEnd = function (time) {
				props.collection.whitelistPeriod.end = moment.utc(time)

				console.log("Whitelist mint period end set to: " + time.format("YYYY-MM-DD HH:mm:ss"))
			}

			window.debug.setPublicPeriodBegin = function (time) {
				props.collection.publicPeriod.begin = moment.utc(time)

				console.log("Public mint period begin set to: " + time.format("YYYY-MM-DD HH:mm:ss"))
			}

			window.debug.setPublicPeriodEnd = function (time) {
				props.collection.publicPeriod.end = moment.utc(time)

				console.log("Public mint period end set to: " + time.format("YYYY-MM-DD HH:mm:ss"))
			}

			window.debug.setAutomaticPeriodsWithOffset = function (offset) {
				window.debug.setWhitelistPeriodBegin(moment.utc(moment.utc().add(offset, "second")))
				window.debug.setWhitelistPeriodEnd(moment.utc(moment.utc().add(offset * 2, "second")))
				window.debug.setPublicPeriodBegin(moment.utc(moment.utc().add(offset * 2, "second")))
				window.debug.setPublicPeriodEnd(moment.utc(moment.utc().add(offset * 3, "second")))
			}
		}
	}, [])

	return (
		<div id="main" className="group">
			{
				props.collection !== undefined &&
				<>
					<img id="nft" src={ props.collection.previewImage } alt="NFT Preview Image"/>
					{
						dialog === Dialog.connect && <ConnectDialog
							collectionUrl={ props.collection.collectionUrl }
							collectionSeriesUrl={ props.collection.collectionSeriesUrl }
							collectionName={ props.collection.collectionName }
							collectionSeriesName={ props.collection.collectionSeriesName }
							onConnect={ connect }
						/>
					}
					{
						dialog === Dialog.whitelistCountdown && <CountdownDialog
							title="Whitelist minting"
							period={ props.collection.whitelistPeriod }
							note={
								<>
									*Expected to sell out quick. Set the reminder to not miss out.
								</>
							}
							joinWhitelistVisible={ true }
							whitelistUrl={ props.collection.whitelistUrl }
						/>
					}
					{
						dialog === Dialog.publicCountdown && <CountdownDialog
							title="Public minting"
							period={ props.collection.publicPeriod }
							note={
								<>
									*Public minting opens 4 hours later than whitelist. Join the whitelist to not miss
									out.
								</>
							}
							joinWhitelistVisible={ false }
							whitelistUrl={ null }
						/>
					}
					{
						(dialog === Dialog.whitelistMint || dialog === Dialog.publicMint) && <MintDialog
							limit={ props.collection.limit }
							price={ props.collection.price }
							address={ shortenAddress(address) }
							onMint={
								dialog === Dialog.whitelistMint ?
									whitelistMint :
									publicMint
							}
						/>
					}
					{
						dialog === Dialog.ended && <EndedDialog
							collectionName={ props.collection.collectionName }
							marketplaceUrl={ props.collection.marketplaceUrl }
						/>
					}
				</>
			}
		</div>
	)
}

export default MainGroup