import "../../../Assets/Styles/Home/BlankSection.scss"

export default function BlankSection(props) {
	return (
		<div id={ props.elementId } className="section">
			<div className="section-text">
				<p className="section-title">{ props.title }</p>
				<p className="section-description">{ props.description }</p>
			</div>
			<div className="section-content">
				{ props.content }
			</div>
		</div>
	)
}