import { subscribeCategory, showSubscribeResponse } from "../../../API/Subscribe"
import SubscribeNewsletters from "../Sections/SubscribeNewsletters"
import "../../../Assets/Styles/Home/TokenSale.scss"

function TokenSale(props) {
	async function register(email) {
		let response = await subscribeCategory(email, [ "token_release" ])

		showSubscribeResponse(response)
	}

	return (
		<>
			<div id="token-sale-tint" className={
				props.isVisible ?
					"token-sale-tint-visible" :
					"token-sale-tint-invisible"
			} onClick={ props.onClose }/>
			<div id="token-sale-container" className={
				props.isVisible ?
					"token-sale-container-visible" :
					"token-sale-container-invisible"
			}>
				<div id="token-sale">
					<p id="token-sale-title">Token Sale</p>
					<p id="token-sale-description">
						{
							"Niten Token has not been released yet,\n" +
							"Be sure to leave us your email in order to not miss out."
						}
					</p>
					<SubscribeNewsletters onRegister={ register }/>
				</div>
			</div>
		</>
	)
}

export default TokenSale