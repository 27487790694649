import GenericBorderLarge from "../../Assets/Images/GenericBorder.png"
import "../../Assets/Styles/Aquarians/TrueWarriors.scss"

function TrueWarriors() {
	return (
		<div id="true-warriors">
			<div id="true-warriors-video">
				<iframe id="true-warriors-video-media"
						title="Official Trailer"
						src="https://youtube.com/embed/B_8U-mADECg"
						allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
				/>
				<img id="true-warriors-video-border" src={ GenericBorderLarge } alt="Video Border"/>
			</div>
			<div className="aquarians-section-text">
				<p className="aquarians-section-title">True Warriors</p>
				<p className="aquarians-section-description">
					The Niten team consists of warriors that serve the idea of being free like a Ronin. DAO is the way. Our aim is to build a decentralized ecosystem that is created and owned by the community.
				</p>
			</div>
		</div>
	)
}

export default TrueWarriors