import { useEffect, useState } from "react"
import { subscribeCategory, showSubscribeResponse } from "../../../API/Subscribe"
import Logo from "../../../Assets/Images/Logo.svg"
import Checkmark from "../../../Assets/Images/Home/Checkmark.svg"
import BlankSection from "./BlankSection"
import SubscribeNewsletters from "./SubscribeNewsletters"
import "../../../Assets/Styles/Home/Register.scss"

function SubscribeOption(props) {
	return (
		<div className="category" onClick={ props.onClick }>
			<div className="category-checkbox">
				<div className={
					props.isChecked ?
						"category-checkbox-box category-checkbox-box-checked" :
						"category-checkbox-box category-checkbox-box-unchecked"
				}>
				</div>
				{ props.isChecked && <img className="category-checkbox-check" src={ Checkmark } alt="Category Checkmark"/> }
			</div>

			<p className="category-title">{ props.title }</p>
		</div>
	)
}

function Register() {
	let [ whitelistChecked, setWhitelistChecked ] = useState(true)
	let [ nftChecked, setNftChecked ] = useState(true)
	let [ tokenChecked, setTokenChecked ] = useState(true)
	let [ betaInvitesChecked, setBetaInvitesChecked ] = useState(true)

	function whitelistToggle() { setWhitelistChecked(!whitelistChecked) }

	function nftToggle() { setNftChecked(!nftChecked) }

	function tokenToggle() { setTokenChecked(!tokenChecked) }

	function betaInvitesToggle() { setBetaInvitesChecked(!betaInvitesChecked) }

	async function register(email) {
		let categories = []

		if (whitelistChecked) categories.push("whitelist_announcements")
		if (nftChecked) categories.push("nft_drops")
		if (tokenChecked) categories.push("token_release")
		if (betaInvitesChecked) categories.push("product_beta_invites")

		let response = await subscribeCategory(email, categories)

		showSubscribeResponse(response)
	}

	return (
		<BlankSection
			elementId="register-section"
			content={
				<div id="register">
					<img id="register-logo" src={ Logo } alt="Logo"/>
					<p id="register-title">Join Our Community</p>
					<p id="register-description">Sign up for Newsletters</p>
					<div id="subscribe-options">
						<SubscribeOption name="whitelist-announcement" title="Whitelist Announcements"
										 isChecked={ whitelistChecked }
										 onClick={ whitelistToggle }
						/>
						<SubscribeOption name="nft-drops" title="NFT Drops"
										 isChecked={ nftChecked }
										 onClick={ nftToggle }
						/>
						<SubscribeOption name="token-release" title="Token Release"
										 isChecked={ tokenChecked }
										 onClick={ tokenToggle }
						/>
						<SubscribeOption name="product-beta-invites" title="Product Beta Invites"
										 isChecked={ betaInvitesChecked }
										 onClick={ betaInvitesToggle }
						/>
					</div>
					<SubscribeNewsletters onRegister={ register }/>
				</div>
			}
		/>
	)
}

export default Register