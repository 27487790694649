import { useState } from "react"

function SubscribeNewsletters(props) {
	let [ email, setEmail ] = useState("")

	async function register() {
		await props.onRegister(email)
	}

	function emailChange(event) {
		setEmail(event.target.value)
	}

	async function emailKeyDown(event) {
		if (
			event.key === "Enter" ||
			event.key === "NumpadEnter"
		) {
			event.preventDefault()

			await register()

			setEmail("")
		}
	}

	return (
		<>
			<input id="subscribe-email" className="input-email" type="email" value={ email } onChange={ emailChange } onKeyDown={ emailKeyDown } placeholder="Enter your email"/>
			<button id="subscribe-action" className="button button-black button-large" onClick={ register }>
				Register
			</button>
		</>
	)
}

export default SubscribeNewsletters