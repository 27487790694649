import { useEffect, useState } from "react"
import LogoSvg from "../../Assets/Images/Logo.svg"
import Arrow from "../../Assets/Images/Home/Arrow.svg"
import Hamburger from "../../Assets/Images/Home/Hamburger.svg"
import "../../Assets/Styles/Home/NavBar.scss"

function Logo() {
	return (
		<a id="navbar-logo-container" href="/">
			<img id="navbar-logo" src={ LogoSvg } alt="Logo"/>
		</a>
	)
}

function Link(props) {
	function scroll() {
		let target = document.getElementById(props.elementId)
		let bodyTop = document.body.getBoundingClientRect().top
		let targetTop = target.getBoundingClientRect().top
		let targetPosition = targetTop - bodyTop
		let targetOffset = targetPosition - 80

		window.scrollTo({
			top: targetOffset,
			behavior: "smooth"
		})
	}

	function click() {
		if (props.onClose !== undefined) props.onClose()

		scroll()
	}

	return (
		<div className="navbar-link-container">
			{
				props.isButton ?
					<button className="button" onClick={ click }>{ props.title }</button> :
					<a className="navbar-link" onClick={ click }>{ props.title }</a>
			}
		</div>
	)
}

function Links(props) {
	return (
		<div className="navbar-links">
			<Link elementId="metaverse-section" title="Metaverse" isButton={ false } onClose={ props.onClose }/>
			<Link elementId="trailer-section" title="Trailer" isButton={ false } onClose={ props.onClose }/>
			<Link elementId="games-section" title="Our Games" isButton={ false } onClose={ props.onClose }/>
			<Link elementId="token-dist-section" title="Token Distribution" isButton={ false } onClose={ props.onClose }/>
			<Link elementId="roadmap-section" title="Roadmap" isButton={ false } onClose={ props.onClose }/>
			<Link elementId="join-in-section" title="Join In" isButton={ false } onClose={ props.onClose }/>
			<Link elementId="team-section" title="Our Team" isButton={ false } onClose={ props.onClose }/>
			{
				props.isMobile && <Link elementId="register-section" title="Register" isButton={ false } onClose={ props.onClose }/>
			}
		</div>
	)
}

function NavBar() {
	let [ isOpened, setIsOpened ] = useState(false)
	let [ isScrolled, setIsScrolled ] = useState(false)

	useEffect(function () {
		function handleScroll() {
			let container = document.getElementById("navbar-container")
			let containerHeightRaw = window.getComputedStyle(container).height
			let containerHeightNumber = containerHeightRaw.match(/\d+/)

			setIsScrolled(window.pageYOffset > containerHeightNumber)
		}

		window.addEventListener("scroll", handleScroll)

		return function () {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [])

	function open() { setIsOpened(true) }

	function close() { setIsOpened(false) }

	return (
		<div id="navbar-container" className={
			isScrolled ?
				"navbar-scrolled" :
				""
		}>
			<div id="navbar-mobile">
				<Logo/>
				<div id="navbar-tint" className={
					isOpened ?
						"navbar-tint-opened" :
						"navbar-tint-closed"
				} onClick={ close }/>
				<div id="navbar-menu-container">
					<div id="navbar-menu" className={
						isOpened ?
							"navbar-menu-opened" :
							"navbar-menu-closed"
					}>
						<img id="navbar-close" src={ Arrow } onClick={ close } alt="NavBar Close"/>
						<Links isMobile={ true } onClose={ close }/>
					</div>
				</div>
				<img id="navbar-open" src={ Hamburger } alt="NavBar Open" onClick={ open }/>
			</div>
			<div id="navbar-desktop">
				<Logo/>
				<Links isMobile={ false }/>
				<Link elementId="register-section" title="Register" isButton={ true }/>
			</div>
		</div>
	)
}

export default NavBar