import BlankSection from "./BlankSection"
import MetaverseSmall from "../../../Assets/Images/Home/Metaverse/Metaverse-Small.png"
import MetaverseLarge from "../../../Assets/Images/Home/Metaverse/Metaverse-Large.png"
import "../../../Assets/Styles/Home/Metaverse.scss"

export default function Metaverse() {
	return (
		<BlankSection
			elementId="metaverse-section"
			title="Niten Metaverse"
			content={
				<>
					<img id="metaverse-small" src={ MetaverseSmall } alt="Metaverse Small"/>
					<img id="metaverse-large" src={ MetaverseLarge } alt="Metaverse Small"/>
				</>
			}
		/>
	)
}