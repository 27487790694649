import BlankSection from "./BlankSection"
import GenericBorder from "../../../Assets/Images/GenericBorder.png"
import "../../../Assets/Styles/Home/WatchTrailer.scss"

function WatchTrailer() {
	return (
		<BlankSection
			elementId="trailer-section"
			content={
				<div id="watch-trailer">
					<div id="watch-trailer-text" className="section-text">
						<p id="watch-trailer-text-title" className="section-title">Watch Trailer</p>
						<p id="watch-trailer-text-description" className="section-description">
							Create unique NFT characters and play with your favorite hero in multiple Niten Metaverse games.
						</p>
					</div>
					<div id="watch-trailer-media">
						<iframe id="watch-trailer-media-video"
								title="Official Trailer"
								src="https://youtube.com/embed/CPWXY7kHRD0"
								allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
						/>
						<img id="watch-trailer-media-video-border" src={ GenericBorder } alt="NFT Mint Border"/>
						<img id="watch-trailer-media-video-pseudo-image" src={ GenericBorder } alt="NFTs Mint Pseudo Image"/>
					</div>
				</div>
			}
		/>
	)
}

export default WatchTrailer