import AquarianNFT from "../../Assets/Images/Aquarians/AquarianNFT.gif"
import "../../Assets/Styles/Aquarians/AquariansInNiten.scss"

function AquariansInNiten() {
	return (
		<div id="aquarians-in-niten">
			<img id="aquarian-nft" src={ AquarianNFT } alt="Aquarian NFT"/>
			<div className="aquarians-section-text">
				<p className="aquarians-section-title">Aquarians in Niten</p>
				<p className="aquarians-section-description">
					Aquarian holders will be part of the web3 virtual world. Become part of Niten and play multiple games, trade, stake and vote to build a community-driven ecosystem.
				</p>
				<div className="aquarians-section-buttons">
					<a className="button button-purple button-large" href="/mint/aquarians">Mint now</a>
					<a className="button button-large" href="#">Get on Opensea</a>
				</div>
			</div>
		</div>
	)
}

export default AquariansInNiten