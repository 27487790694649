import Zuka from "../../../Assets/Images/Home/Team/Advisors/Zuka.png"
import Everett from "../../../Assets/Images/Home/Team/Advisors/Everett.png"
import Daniel from "../../../Assets/Images/Home/Team/Advisors/Daniel.png"
import Michael from "../../../Assets/Images/Home/Team/Advisors/Michael.png"
import Eddie from "../../../Assets/Images/Home/Team/Advisors/Eddie.png"
import David from "../../../Assets/Images/Home/Team/Advisors/David.png"
import Natia from "../../../Assets/Images/Home/Team/Team/Natia.png"
import Constantine from "../../../Assets/Images/Home/Team/Team/Constantine.png"
import Dimitri from "../../../Assets/Images/Home/Team/Team/Dimitri.png"
import Ika from "../../../Assets/Images/Home/Team/Team/Ika.png"
import Peter from "../../../Assets/Images/Home/Team/Team/Peter.png"
import Alice from "../../../Assets/Images/Home/Team/Team/Alice.png"
import George from "../../../Assets/Images/Home/Team/Team/George.png"
import Maya from "../../../Assets/Images/Home/Team/Team/Maya.png"
import Lasha from "../../../Assets/Images/Home/Team/Team/Lasha.png"
import David2 from "../../../Assets/Images/Home/Team/Team/David2.png"
import Anna from "../../../Assets/Images/Home/Team/Team/Anna.png"
import Tina from "../../../Assets/Images/Home/Team/Team/Tina.png"
import Anya from "../../../Assets/Images/Home/Team/Team/Anya.png"
import Paata from "../../../Assets/Images/Home/Team/Team/Paata.png"
import Sonya from "../../../Assets/Images/Home/Team/Team/Sonya.png"
import Roma from "../../../Assets/Images/Home/Team/Team/Roma.png"
import Saba from "../../../Assets/Images/Home/Team/Team/Saba.png"
import Tako from "../../../Assets/Images/Home/Team/Team/Tako.png"
import Nick from "../../../Assets/Images/Home/Team/Team/Nick.png"
import Omar from "../../../Assets/Images/Home/Team/Team/Omar.png"
import Anna2 from "../../../Assets/Images/Home/Team/Team/Anna2.png"
import BlankSection from "./BlankSection"
import "../../../Assets/Styles/Home/Team.scss"

function Member(props) {
	return (
		<div className="team-member">
			<img className="team-member-image" src={ props.image } alt="Advisor Member"/>
			<p className="team-member-name">{ props.name }</p>
			<p className="team-member-role">{ props.role }</p>
			{
				props.experience !== undefined && <p className="team-member-experience">{ props.experience }</p>
			}
		</div>
	)
}

function Team() {
	return (
		<BlankSection
			elementId="team-section"
			title="Team & Advisors"
			content={
				<div id="team">
					<div id="advisor-members">
						<Member
							image={ Zuka }
							name="Zuka"
							role="CEO"
							experience={
								"- 10+ Year Founder: Creator of mobile gaming company Appidea, with offices in Georgia, Ukraine and the UK\n" +
								"- Multiple launches of successful projects with millions of downloads: Pixel Swordfish io, Hero Wings and Water Planet\n" +
								"- Enthusiasm for the crypto space and blockchain technologies led to the Niten.io project in 2021"
							}
						/>
						<Member
							image={ Everett }
							name="Everett Kamin"
							role="CFO"
							experience={
								"- Successful track record of 100+ transactions, totaling in excess of $50B, spanning over 24 years of combined Investment Banking, Private Equity, Venture Capital and Public/Private Board experience\n" +
								"- Previously at BofA, Wells Fargo, Merrill Lynch, Goldman Sachs Board Representation: Warsaw, London and Helsinki Stock Exchange listed companies\n" +
								"- MBA – Warwick Business School\n" +
								"- Executive Education – The Wharton Business School, University of Pennsylvania"
							}
						/>
						<Member
							image={ Daniel }
							name="Daniel Moroz"
							role="Advisor"
							experience={
								"- Protocol Research Scientists at Chainlink Labs\n" +
								"- Founded Harvard's First Cryptocurrency Research Group with Professor David Parkes\n" +
								"- MIT Digital Currency Initiative Affiliate Software Engineer(Intern/2014) – Google Quantitative Research (MANA Partners LLC/Quantopian)\n" +
								"- Ph.D. Computer Science – Harvard University\n" +
								"- M.S. Robotics – University of Pennsylvania\n" +
								"- B.S. Computer Science – University of Pennsylvania"
							}
						/>
						<Member
							image={ Michael }
							name="Michael Narinksy"
							role="Advisor"
							experience={
								"- Senior Product Manager at Google Payments\n" +
								"- Previously Senior Software Engineer at Samsung\n" +
								"- Electronics, Google and Verivo\n" +
								"- MBA – MIT Sloan School of Management\n" +
								"- B.S. Computer Science – CIS Lomonosov"
							}
						/>
						<Member
							image={ Eddie }
							name="Eddie Quiroz"
							role="Advisor"
							experience={
								"- Extensive experience in API Design and Architecture in enterprise software implementations\n" +
								"- Founded and launched numerous successful blockchain / IT companies: NexBloc, AVRILAR, IOTric, NexWEB\n" +
								"- Emeritus board member of the MIT Enterprise\n" +
								"- Forum of NYC & Active MIT Alumni Angel of NYC, MIT Sloan Club of NYC board member, Masters – MIT"
							}
						/>
						<Member
							image={ David }
							name="David Lahav"
							role="Advisor"
							experience={
								"- 15+ years of experience in leadership positions across online businesses in multiple verticals within the Israely ecosystem\n" +
								"- Early-stage investor & consultant in buff.game(completed IPO in Israel Stock Exchange)\n" +
								"- Blockchain and cryptocurrency investor with a craving for technology"
							}
						/>
					</div>
					<div id="team-members">
						<Member image={ Natia } name="Natia" role="Art Director"/>
						<Member image={ Constantine } name="Constantine" role="Technical Artist / Game Designer"/>
						<Member image={ Dimitri } name="Dimitri" role="Data Analyst"/>
						<Member image={ Ika } name="Ika" role="Product Owner / Game Designer"/>
						<Member image={ Peter } name="Peter" role="Blockchain / Full-stack Developer"/>
						<Member image={ Alice } name="Alice" role="UI/UX Designer"/>
						<Member image={ George } name="George" role="C++ / iOS Developer"/>
						<Member image={ Maya } name="Maya" role="2D Artist"/>
						<Member image={ Lasha } name="Lasha" role="Animator / 3D Artist"/>
						<Member image={ David2 } name="David" role="VFX Artist / Audio Lead"/>
						<Member image={ Anna } name="Anna" role="Level Designer / Animator"/>
						<Member image={ Tina } name="Tina" role="Concept Artist"/>
						<Member image={ Anya } name="Anya" role="Social Media Manager"/>
						<Member image={ Paata } name="Paata" role="Unity Developer"/>
						<Member image={ Sonya } name="Sonya" role="Character Artist"/>
						<Member image={ Roma } name="Roma" role="Lead Animator / Artist"/>
						<Member image={ Saba } name="Saba" role="VFX Artist / Animator"/>
						<Member image={ Nick } name="Nick" role="Community Growth Manager"/>
						<Member image={ Tako } name="Tako" role="2D Artist"/>
						<Member image={ Omar } name="Omar" role="Character Artist"/>
						<Member image={ Anna2 } name="Anna" role="Media Buyer"/>
					</div>
				</div>
			}
		/>
	)
}

export default Team