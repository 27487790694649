import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faYoutube, faDiscord } from "@fortawesome/free-brands-svg-icons"
import BlankSection from "./BlankSection"
import "../../../Assets/Styles/Home/Footer.scss"

function Footer() {
	return (
		<BlankSection
			elementId="footer-section"
			content={
				<div id="footer-container">
					<p id="footer-copyright">2022 Niten © All rights reserved.</p>
					<ul id="footer-socials">
						<li className="footer-social button">
							<a href="https://twitter.com/NitenProject" target="_blank">
								<FontAwesomeIcon className="footer-social-icon" icon={ faTwitter }/>
							</a>
						</li>
						<li className="footer-social button">
							<a href="https://www.youtube.com/channel/UCBSNwr2RSYmEaYw7VyH8zVA" target="_blank">
								<FontAwesomeIcon className="footer-social-icon" icon={ faYoutube }/>
							</a>
						</li>
						<li className="footer-social button">
							<a href="https://discord.gg/u5SuW4eCZu" target="_blank">
								<FontAwesomeIcon className="footer-social-icon" icon={ faDiscord }/>
							</a>
						</li>
					</ul>
				</div>
			}
		/>
	)
}

export default Footer