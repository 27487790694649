import "../../Assets/Styles/Aquarians/StoryOfAquarians.scss"

function StoryOfAquarians() {
	return (
		<div id="story-of-aquarians" className="card">
			<div className="aquarians-section-text">
				<p className="aquarians-section-title">Story of Aquarians</p>
				<p className="aquarians-section-description">
					Aquarians are an advanced intergalactic species. Among other things they developed the technology to teleport between different worlds. This gave them the chance to explore the furthest corners of space and find safe havens across the galaxy. Join the Aquarians on a journey through space.
				</p>
			</div>
		</div>
	)
}

export default StoryOfAquarians