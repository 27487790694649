import { useEffect } from "react"
import Logo from "../../Assets/Images/Logo.svg"
import Static from "../../Assets/Images/Home/Parallax/Static.png"
import "../../Assets/Styles/Home/Parallax.scss"

function Parallax() {
	useEffect(function () {
		let layers = document.getElementById("parallax-layers")
		let layer1 = document.getElementById("parallax-layer-1")
		let layer2 = document.getElementById("parallax-layer-2")
		let layer3 = document.getElementById("parallax-layer-3")
		let layer4 = document.getElementById("parallax-layer-4")
		let _static = document.getElementById("parallax-static")
		let pseudoImage = document.getElementById("parallax-pseudo-image")

		function moveLayer(mousePosition, layer, container, speed) {
			let containerWidth = layers.offsetWidth
			let containerHeight = layers.offsetHeight

			const deltaXPercent = (layer.offsetWidth - containerWidth) / containerWidth / 2
			const deltaYPercent = (layer.offsetHeight - containerHeight) / containerHeight / 2

			const mouseOffsetX = (containerWidth / 2) - mousePosition.x
			const mouseOffsetY = (containerHeight / 2) - mousePosition.y

			const moveXPercent = ((mouseOffsetX / containerWidth) * speed) - (deltaXPercent * 100)
			const moveYPercent = ((mouseOffsetY / containerHeight) * speed) - (deltaYPercent * 100)

			layer.style.transform = "translate(" + moveXPercent + "%, " + moveYPercent + "%)"
		}

		function handleMouseMove(event) {
			let mousePosition = {
				x: event !== undefined ? event.pageX : window.innerWidth / 2,
				y: event !== undefined ? event.pageY : window.innerHeight / 2
			}

			moveLayer(mousePosition, layer1, layers, 0)
			moveLayer(mousePosition, layer2, layers, 0.25)
			moveLayer(mousePosition, layer3, layers, 1)
			moveLayer(mousePosition, layer4, layers, 2)
		}

		handleMouseMove()

		function copySize(source, target) {
			target.style.width = window.getComputedStyle(source).width
			target.style.height = window.getComputedStyle(source).height
		}

		function copySizes() {
			copySize(pseudoImage, layers)
			copySize(pseudoImage, _static)
		}

		let copyInterval = setInterval(copySizes, 100)

		layers.addEventListener("mousemove", handleMouseMove)

		return function () {
			clearInterval(copyInterval)

			layers.removeEventListener("mousemove", handleMouseMove)
		}
	}, [])

	return (
		<div id="parallax">
			<div id="parallax-center">
				<img id="parallax-logo" src={ Logo } alt="Logo"/>
				<p id="parallax-text">
					NFT Gaming Ecosystem
				</p>
			</div>
			<div id="parallax-layers">
				<div className="parallax-layer" id="parallax-layer-1"/>
				<div className="parallax-layer" id="parallax-layer-2"/>
				<div className="parallax-layer" id="parallax-layer-3"/>
				<div className="parallax-layer" id="parallax-layer-4"/>
			</div>
			<div className="parallax-layer" id="parallax-static"/>
			<img id="parallax-pseudo-image" src={ Static } alt="Parallax Pseudo Image"/>
		</div>
	)
}

export default Parallax