import { useEffect, useState } from "react"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClipboardList } from "@fortawesome/free-solid-svg-icons"
import "../../Assets/Styles/Mint/CountdownDialog.scss"

function CountdownTimerUnit(props) {
	return (
		<div className="countdown-timer-unit">
			<div className="countdown-timer-unit-text countdown-timer-unit-part">
				<p className="countdown-timer-unit-text-value countdown-timer-unit-part-value">
					{ props.text }
				</p>
			</div>
			<div className="countdown-timer-unit-name countdown-timer-unit-part">
				<p className="countdown-timer-unit-name-value countdown-timer-unit-part-value">
					{ props.name }
				</p>
			</div>
		</div>
	)
}

function CountdownTimer(props) {
	let [ text, setText ] = useState({
		days: "99",
		hours: "99",
		minutes: "99",
		seconds: "99"
	})

	useEffect(function () {
		function updateText() {
			function formatUnit(unit) {
				return unit.toString().padStart(2, "0")
			}

			let duration = moment.duration(props.period.begin.diff(moment()))

			setText({
				days: formatUnit(duration.days()),
				hours: formatUnit(duration.hours()),
				minutes: formatUnit(duration.minutes()),
				seconds: formatUnit(duration.seconds())
			})
		}

		updateText()

		let timerInterval = setInterval(updateText, 1000)

		return function () {
			clearInterval(timerInterval)
		}
	}, [])

	return (
		<div className="countdown-timer">
			<CountdownTimerUnit text={ text.days } name="Days"/>
			<CountdownTimerUnit text={ text.hours } name="Hours"/>
			<CountdownTimerUnit text={ text.minutes } name="Mins"/>
			<CountdownTimerUnit text={ text.seconds } name="Secs"/>
		</div>
	)
}

function CountdownDialog(props) {
	let time = props.period.begin.format("HH:mm")
	let date = props.period.begin.format("DD:MM:YY")

	return (
		<>
			<p className="dialog-title">{ props.title } { time } UTC, { date }.</p>
			<CountdownTimer period={ props.period }/>
			<p className="dialog-note">{ props.note }</p>
			{
				props.joinWhitelistVisible &&
				<a className="button-icon button button-large" href={ props.whitelistUrl } target="_blank">
					<FontAwesomeIcon icon={ faClipboardList }/>
					Join the Whitelist
				</a>
			}
		</>
	)
}

export default CountdownDialog