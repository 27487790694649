import { useState } from "react"
import Preloader from "../Components/Preloader"
import Background from "../Components/Home/Background"
import NavBar from "../Components/Home/NavBar"
import Parallax from "../Components/Home/Parallax"
import Unisection from "../Components/Home/Sections/Unisection"
import TokenDist from "../Components/Home/Sections/TokenDist"
import Roadmap from "../Components/Home/Sections/Roadmap"
import JoinIn from "../Components/Home/Sections/JoinIn"
import Team from "../Components/Home/Sections/Team"
import Register from "../Components/Home/Sections/Register"
import Footer from "../Components/Home/Sections/Footer"
import "../Assets/Styles/Home/Home.scss"

function Content() {
	return (
		<>
			<Background/>
			<NavBar/>
			<Parallax/>
			<Unisection/>
			<TokenDist/>
			<Roadmap/>
			<JoinIn/>
			<Team/>
			<Register/>
			<Footer/>
		</>
	)
}

function Home() {
	let [ isPreloaderMounted, setIsPreloaderMounted ] = useState(false)

	function handlePreloaderMounted() {
		setIsPreloaderMounted(true)
	}

	return (
		<>
			<Preloader onMount={ handlePreloaderMounted }/>
			{
				isPreloaderMounted && <Content/>
			}
		</>
	)
}

export default Home