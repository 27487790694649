import { createRoot } from "react-dom/client"
import { StrictMode } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { initializeFirebaseApp } from "./Services/Firebase"
import Home from "./Pages/Home"
import Aquarians from "./Pages/Aquarians"
import Mint from "./Pages/Mint"
import NotFound from "./Pages/NotFound"

initializeFirebaseApp()

let container = document.getElementById("container")

let root = createRoot(container)

root.render(
	<StrictMode>
		<BrowserRouter>
			<Routes>
				<Route exact path="/" element={ <Home/> }/>
				<Route exact path="/aquarians" element={ <Aquarians/> }/>
				<Route exact path="/mint/:collectionName" element={ <Mint/> }/>
				<Route path="*" element={ <NotFound/> }/>
			</Routes>
		</BrowserRouter>
	</StrictMode>
)