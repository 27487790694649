function isDevBuild() {
	if (process.env.REACT_APP_DEV_BUILD !== undefined) {
		if (process.env.REACT_APP_DEV_BUILD.trim() === "true") return true
		else if (process.env.REACT_APP_DEV_BUILD.trim() === "false") return false
		else console.error("Invalid value of REACT_APP_DEV_BUILD.")
	}

	return false
}

export { isDevBuild }