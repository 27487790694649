function ConnectDialog(props) {
	let collectionLink =
		<a className="dialog-note-link" href={ props.collectionUrl } target="_blank">
			{ props.collectionName }
		</a>

	let collectionSeriesLink =
		<a className="dialog-note-link" href={ props.collectionSeriesUrl } target="_blank">
			{ props.collectionSeriesName }
		</a>

	return (
		<>
			<p className="dialog-note">
				*Connect your wallet to mint { collectionLink } from the { collectionSeriesLink }.
			</p>
			<button id="connect" className="button button-purple button-large" onClick={ props.onConnect }>
				Connect to Wallet
			</button>
		</>
	)
}

export default ConnectDialog