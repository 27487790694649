import BlankSection from "./BlankSection"
import "../../../Assets/Styles/Home/TokenDist.scss"

function ChartCircle(props) {
	return (
		<li className="token-dist-chart-circle" id={ props.elementId }>
			<p className="token-dist-chart-circle-text">
				{ props.percent }
			</p>
		</li>
	)
}

function LegendRow(props) {
	return (
		<li className="token-dist-chart-legend-row">
			<div className="token-dist-chart-legend-row-icon" id={ props.elementId }/>
			<div className="token-dist-chart-legend-row-data">
				<p className="token-dist-chart-legend-row-title token-dist-chart-legend-row-text">
					{ props.title }
				</p>
				<p className="token-dist-chart-legend-row-percent token-dist-chart-legend-row-text">
					{ props.percent }
				</p>
			</div>
		</li>
	)
}

function TokenDist() {
	return (
		<BlankSection
			elementId="token-dist-section"
			title="Token Distribution"
			content={
				<div id="token-dist">
					<ul id="token-dist-chart">
						<ChartCircle elementId="token-dist-chart-circle7" percent="3%"/>
						<ChartCircle elementId="token-dist-chart-circle6" percent="3%"/>
						<ChartCircle elementId="token-dist-chart-circle5" percent="8%"/>
						<ChartCircle elementId="token-dist-chart-circle4" percent="10%"/>
						<ChartCircle elementId="token-dist-chart-circle3" percent="18%"/>
						<ChartCircle elementId="token-dist-chart-circle2" percent="18%"/>
						<ChartCircle elementId="token-dist-chart-circle1" percent="40%"/>
					</ul>
					<ul id="token-dist-legend">
						<LegendRow elementId="token-dist-legend-row-icon-1" title="In-Game / Staking Rewards" percent="40%"/>
						<LegendRow elementId="token-dist-legend-row-icon-2" title="Team & Advisor" percent="18%"/>
						<LegendRow elementId="token-dist-legend-row-icon-3" title="Seed / Private Sale" percent="18%"/>
						<LegendRow elementId="token-dist-legend-row-icon-4" title="Ecosystem Fund" percent="10%"/>
						<LegendRow elementId="token-dist-legend-row-icon-5" title="Public Sale" percent="8%"/>
						<LegendRow elementId="token-dist-legend-row-icon-6" title="Liquidity" percent="3%"/>
						<LegendRow elementId="token-dist-legend-row-icon-7" title="Community & Marketing" percent="3%"/>
					</ul>
				</div>
			}
		/>
	)
}

export default TokenDist