import Aquarians from "../Assets/Images/Mint/Aquarians.gif"
import MetaverseSenateABI from "../ABIs/MetaverseSenatorABI.json"
import moment from "moment"

const Collections = {
	"aquarians": {
		previewImage: Aquarians,
		devContractAddress: "0x386d631E85e8b0b76F298Ad48e87159572bDC6a2",
		prodContractAddress: "0x",
		contractAbi: MetaverseSenateABI,
		price: 0.1,
		limit: 5,
		whitelistPeriod: {
			begin: moment.utc("2022-06-10 14:00"),
			end: moment.utc("2022-06-10 15:00")
		},
		publicPeriod: {
			begin: moment.utc("2022-06-10 15:00"),
			end: moment.utc("2022-06-12 15:00")
		},
		whitelistUrl: "https://premint.xyz",
		marketplaceUrl: "https://opensea.io",
		collectionName: "Aquarians",
		collectionSeriesName: "Metaverse Senate",
		collectionUrl: "https://niten.io/collections/metaverse_senate/aquarians",
		collectionSeriesUrl: "https://niten.io/collections/metaverse_senate"
	}
}

function getCollectionByName(collectionName) {
	return Collections[collectionName]
}

export { getCollectionByName }