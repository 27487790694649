import Logo from "../Assets/Images/Logo.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faYoutube, faDiscord } from "@fortawesome/free-brands-svg-icons"
import "../Assets/Styles/Header.scss"

export default function Header() {
	return (
		<div id="header">
			<a id="logo-container" href="/">
				<img id="logo" src={ Logo } alt="Logo"/>
			</a>
			<div id="socials">
				<a className="social" href="https://twitter.com/NitenProject" target="_blank">
					<FontAwesomeIcon className="social-icon" icon={ faTwitter }/>
				</a>
				<a className="social" href="https://www.youtube.com/channel/UCBSNwr2RSYmEaYw7VyH8zVA" target="_blank">
					<FontAwesomeIcon className="social-icon" icon={ faYoutube }/>
				</a>
				<a className="social" href="https://discord.com/invite/u5SuW4eCZu" target="_blank">
					<FontAwesomeIcon className="social-icon" icon={ faDiscord }/>
				</a>
			</div>
		</div>
	)
}