import { initializeApp } from "firebase/app"

let firebaseApp

function initializeFirebaseApp() {
	firebaseApp = initializeApp({
		apiKey: "AIzaSyBgl1Hmr2Iq7hNu397CdaLT6-FlDRcgpUg",
		authDomain: "niten-5937b.firebaseapp.com",
		projectId: "niten-5937b",
		storageBucket: "niten-5937b.appspot.com",
		messagingSenderId: "822320303587",
		appId: "1:822320303587:web:1116a918798e92963be4d6",
		measurementId: "G-CV5G7CC5BW"
	})
}

export { firebaseApp, initializeFirebaseApp }