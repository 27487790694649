import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

function EndedDialog(props) {
	return (
		<>
			<p className="dialog-note">
				*Minting has ended. { props.collectionName } are now available on the marketplace.
			</p>
			<a className="button-icon button button-large" href={ props.marketplaceUrl } target="_blank">
				<FontAwesomeIcon className="marketplace-icon" icon={ faShoppingCart }/>
				Get your { props.collectionName }
			</a>
		</>
	)
}

export default EndedDialog