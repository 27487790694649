import "../../Assets/Styles/Mint/PopupGroup.scss"

function PopupGroup(props) {
	return (
		<div id="popup" className="group">
			<img id="popup-icon" src={ props.icon } alt="Popup Icon"/>
			<p id="popup-message">{ props.message }</p>
			{ props.body }
			<button id="popup-close" className="button" onClick={ props.onClose }>
				Close
			</button>
		</div>
	)
}

export default PopupGroup