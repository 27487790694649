import Metaverse from "./Metaverse"
import WatchTrailer from "./WatchTrailer"
import AquarianNFTCollection from "./AquarianNFTCollection"
import MergeHeroesNFTs from "./MergeHeroesNFTs"
import OurGames from "./OurGames"
import UnisectionImage from "../../../Assets/Images/Home/Unisection.png"
import "../../../Assets/Styles/Home/Unisection.scss"

export default function Unisection() {
	return (
		<div id="unisection">
			<img id="unisection-pseudo-image" src={ UnisectionImage } alt="Unisection Pseudo Image"/>
			<Metaverse/>
			<WatchTrailer/>
			<AquarianNFTCollection/>
			<MergeHeroesNFTs/>
			<OurGames/>
		</div>
	)
}