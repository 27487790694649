import { useState } from "react"
import TokenSale from "./TokenSale"
import BlankSection from "./BlankSection"
import BuyNFTs from "../../../Assets/Images/Home/JoinIn/BuyNFTs.png"
import BuyTokens from "../../../Assets/Images/Home/JoinIn/BuyTokens.png"
import FollowUs from "../../../Assets/Images/Home/JoinIn/FollowUs.png"
import "../../../Assets/Styles/Home/JoinIn.scss"

function JoinIn() {
	let [ tokenSaleVisible, setTokenSaleVisible ] = useState(false)

	function showTokenSale() {
		setTokenSaleVisible(true)
	}

	function closeTokenSale() {
		setTokenSaleVisible(false)
	}

	return (
		<BlankSection
			elementId="join-in-section"
			title="Join In!"
			description="The Future of Virtual World"
			content={
				<>
					<TokenSale isVisible={ tokenSaleVisible } onClose={ closeTokenSale }/>
					<div id="join-in">
						<div className="join-in-activity">
							<img className="join-in-activity-icon" src={ BuyNFTs } alt="Buy NFTs"/>
							<a className="button button-purple button-large" href="https://opensea.io/nitenNFTs" target="_blank">
								Buy NFTs
							</a>
						</div>
						<div className="join-in-activity">
							<img className="join-in-activity-icon" src={ BuyTokens } alt="Buy Tokens"/>
							<button className="button button-purple button-large" id="join-in-buy-tokens" onClick={ showTokenSale }>
								Buy Tokens
							</button>
						</div>
						<div className="join-in-activity">
							<img className="join-in-activity-icon" src={ FollowUs } alt="Follow Us"/>
							<a className="button button-purple button-large" href="https://twitter.com/NitenProject/" target="_blank">
								Follow Us
							</a>
						</div>
					</div>
				</>
			}
		/>
	)
}

export default JoinIn