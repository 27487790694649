import { useState } from "react"
import PopupGroup from "./PopupGroup"
import MainGroup from "./MainGroup"
import ErrorIcon from "../../Assets/Images/Mint/ErrorIcon.svg"
import SuccessIcon from "../../Assets/Images/Mint/SuccessIcon.svg"

function Content(props) {
	let [ popupInfo, setPopupInfo ] = useState({
		visible: false
	})

	function showPopup(icon, message, body) {
		setPopupInfo({
			visible: true,
			icon: icon,
			message: message,
			body: body,
			onClose: function () {
				setPopupInfo({ visible: false })
			}
		})
	}

	function showSuccess(message) {
		showPopup(SuccessIcon, message)
	}

	function showError(message, body) {
		showPopup(ErrorIcon, message, body)
	}

	return (
		<div id="content">
			{
				popupInfo.visible ?
					<PopupGroup
						icon={ popupInfo.icon }
						message={ popupInfo.message }
						body={ popupInfo.body }
						onClose={ popupInfo.onClose }
					/> :
					<MainGroup
						collection={ props.collection }
						onSuccess={ showSuccess }
						onError={ showError }
					/>
			}
		</div>
	)
}

export default Content