import BlankSection from "./BlankSection"
import "../../../Assets/Styles/Home/Roadmap.scss"

function Year(props) {
	return (
		<div className="roadmap-year">
			<p className="roadmap-year-title">{ props.title }</p>
			<div className="roadmap-year-line"/>
			<div className="roadmap-year-point">
				<div className="roadmap-year-point-center"/>
			</div>
		</div>
	)
}

function Milestone(props) {
	return (
		<div className="roadmap-milestone">
			{
				<div className={
					props.isCompleted ?
						"roadmap-milestone-status roadmap-milestone-complete" :
						"roadmap-milestone-status roadmap-milestone-todo"
				}/>
			}
			<p className="roadmap-milestone-text">{ props.text }</p>
		</div>
	)
}

function Quarter(props) {
	return (
		<div className="roadmap-quarter">
			{/*<p className="roadmap-quarter-title">{ props.title }</p>*/}
			{ props.milestones }
		</div>
	)
}

function QuarterEnd() {
	return (
		<div className="roadmap-quarter-end">
			<div className="roadmap-quarter-point">
				<div className="roadmap-quarter-point-line"/>
			</div>
		</div>
	)
}

function Roadmap() {
	return (
		<BlankSection
			elementId="roadmap-section"
			title="Roadmap"
			content={
				<div id="roadmap">
					<div id="roadmap-line"/>
					<div id="roadmap-data">
						<Year title="2021"/>
						<Quarter title="Q2" milestones={
							<>
								<Milestone isCompleted={ true } text={ "Foundation of the Blockchain Gaming Ecosystem \"Niten.io\"" }/>
							</>
						}/>
						<QuarterEnd/>
						<Quarter title="Q3" milestones={
							<>
								<Milestone isCompleted={ true } text={ "Began development of Idle RPG game, \"Merge Heroes\"" }/>
							</>
						}/>
						<QuarterEnd/>
						<Quarter title="Q4" milestones={
							<>
								<Milestone isCompleted={ true } text={ "Established partnership with \"VR Kemono\" to publish VR game" }/>
								<Milestone isCompleted={ true } text={ "Merge Heroes private alpha release" }/>
							</>
						}/>
						<Year title="2022"/>
						<Quarter title="Q1" milestones={
							<>
								<Milestone isCompleted={ true } text={ "Began development of Gamified Decentralized Exchange, \"DeFi Planet\"" }/>
								<Milestone isCompleted={ true } text={ "Community growth: Discord, Twitter, Reddit, Telegram" }/>
							</>
						}/>
						<QuarterEnd/>
						<Quarter title="Q2" milestones={
							<>
								<Milestone isCompleted={ true } text={ "Niten Ecosystem teaser trailer" }/>
								<Milestone isCompleted={ true } text={ "Preparation of the first NFT collection" }/>
							</>
						}/>
						<QuarterEnd/>
						<Quarter title="Q3" milestones={
							<>
								<Milestone isCompleted={ false } text={ "Launch of the first NFT collection" }/>
								<Milestone isCompleted={ false } text={ "DeFi Planet private alpha release" }/>
								<Milestone isCompleted={ false } text={ "Token development & audit" }/>
								<Milestone isCompleted={ false } text={ "Token release" }/>
							</>
						}/>
						<QuarterEnd/>
						<Quarter title="Q4" milestones={
							<>
								<Milestone isCompleted={ false } text={ "Listing on CoinMarketCap & CoinGecko" }/>
								<Milestone isCompleted={ false } text={ "Niten Ecosystem gameplay trailer" }/>
								<Milestone isCompleted={ false } text={ "DeFi Planet private beta release" }/>
								<Milestone isCompleted={ false } text={ "Launching DeFi Staking mechanisms" }/>
							</>
						}/>
						<Year title="TBA"/>
						<Quarter title="Q1" milestones={
							<>
								<Milestone isCompleted={ false } text={ "VR game private alpha release" }/>
								<Milestone isCompleted={ false } text={ "Merge Heroes private beta release" }/>
							</>
						}/>
						<QuarterEnd/>
						<Quarter title="Q2" milestones={
							<>
								<Milestone isCompleted={ false } text={ "Niten Ecosystem cinematic trailer" }/>
							</>
						}/>
						<QuarterEnd/>
						<Quarter title="Q3" milestones={
							<>
								<Milestone isCompleted={ false } text={ "Launching Niten NFT Marketplace" }/>
							</>
						}/>
						<QuarterEnd/>
						<Quarter title="Q4" milestones={
							<>
								<Milestone isCompleted={ false } text={ "Land pre-sale (20%)" }/>
							</>
						}/>
						<Year title="TBA"/>
						<Quarter title="Q1" milestones={
							<>
								<Milestone isCompleted={ false } text={ "Land pre-sale (30%)" }/>
								<Milestone isCompleted={ false } text={ "Launch of the Niten Ecosystem marketing campaign" }/>
							</>
						}/>
						<QuarterEnd/>
						<Quarter title="Q2" milestones={
							<>
								<Milestone isCompleted={ false } text={ "Progressive opening of the Niten Ecosystem to the public" }/>
							</>
						}/>
						<QuarterEnd/>
						<Quarter title="Q3" milestones={
							<>
								<Milestone isCompleted={ false } text={ "Niten Ecosystem land sale (50%)" }/>
							</>
						}/>
						<QuarterEnd/>
						<Quarter title="Q4" milestones={
							<>
								<Milestone isCompleted={ false } text={ "Launch of the DAO with staking and voting mechanisms" }/>
							</>
						}/>
					</div>
				</div>
			}/>
	)
}

export default Roadmap