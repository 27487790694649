import MetamaskIcon from "../../Assets/Images/Mint/Wallets/Metamask.svg"
import WalletConnectIcon from "../../Assets/Images/Mint/Wallets/WalletConnect.svg"
import CoinbaseWalletIcon from "../../Assets/Images/Mint/Wallets/CoinbaseWallet.svg"
import "../../Assets/Styles/Mint/SuggestedWallets.scss"

function Wallet(props) {
	return (
		<a className="wallet" href={ props.url } target="_blank">
			<img className="wallet-icon" src={ props.icon } alt="Wallet Icon"/>
			<p className="wallet-name">{ props.name }</p>
		</a>
	)
}

function SuggestedWallets() {
	return (
		<div id="suggested-wallets">
			<Wallet icon={ MetamaskIcon } name="Metamask" url="https://metamask.io/"/>
			<Wallet icon={ WalletConnectIcon } name="WalletConnect" url="https://walletconnect.com/"/>
			<Wallet icon={ CoinbaseWalletIcon } name="CoinBase Wallet" url="https://www.coinbase.com/wallet"/>
		</div>
	)
}

export default SuggestedWallets