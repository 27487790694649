import "../../Assets/Styles/Home/Background.scss"

function Background() {
	return (
		<div id="background">
			<div id="background-flare-1"/>
			<div id="background-flare-2"/>
		</div>
	)
}

export default Background