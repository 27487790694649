import GalaxyBackground from "../Components/Aquarians/GalaxyBackground"
import UtilityNFTs from "../Components/Aquarians/UtilityNFTs"
import TrueWarriors from "../Components/Aquarians/TrueWarriors"
import StoryOfAquarians from "../Components/Aquarians/StoryOfAquarians"
import AquariansInNiten from "../Components/Aquarians/AquariansInNiten"
import "../Assets/Styles/Aquarians/Aquarians.scss"

function Aquarians() {
	return (
		<>
			<GalaxyBackground/>
			<UtilityNFTs/>
			<TrueWarriors/>
			<StoryOfAquarians/>
			<AquariansInNiten/>
		</>
	)
}

export default Aquarians