import { useEffect, useState } from "react"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import BlankSection from "./BlankSection"
import MergeHeroes_1 from "../../../Assets/Images/Home/OurGames/OurGame_MergeHeroes_1.png"
import MergeHeroes_2 from "../../../Assets/Images/Home/OurGames/OurGame_MergeHeroes_2.png"
import MergeHeroes_3 from "../../../Assets/Images/Home/OurGames/OurGame_MergeHeroes_3.png"
import MergeHeroes_4 from "../../../Assets/Images/Home/OurGames/OurGame_MergeHeroes_4.png"
import VirtualLand from "../../../Assets/Images/Home/OurGames/OurGame_VirtualLand.png"
import GenericBorder from "../../../Assets/Images/GenericBorder.png"
import "@splidejs/splide/dist/css/splide.min.css"
import "../../../Assets/Styles/Home/OurGames.scss"

function OurGame(props) {
	return (
		<SplideSlide>
			<img className="game" src={ props.image } alt="Our Game"/>
		</SplideSlide>
	)
}

function OurGamesList() {
	return (
		<>
			<OurGame image={ MergeHeroes_1 }/>
			<OurGame image={ MergeHeroes_2 }/>
			<OurGame image={ MergeHeroes_3 }/>
			<OurGame image={ MergeHeroes_4 }/>
			<OurGame image={ VirtualLand }/>
		</>
	)
}

function OurGames() {
	let [ splideIndex, setSplideIndex ] = useState(0)
	let [ textInfo, setTextInfo ] = useState({
		title: "",
		description: ""
	})

	useEffect(function () {
		const MergeHeroesTitle = "Merge Heroes"
		const MergeHeroesDescription = "Play to earn idle RPG game. Summon heroes by merging cards, battle in online and offline modes, unlock and trade unique NFT heroes and breed dragons."
		const VirtualLandTitle = "Virtual Land"
		const VirtualLandDescription = "Owners of limited land can produce crafting materials and trade them across games within the Niten ecosystem. Lands in the center of Niten metaverse will be more resourceful and high valued. Land owners can also monetize on their properties through ads."

		if (splideIndex >= 0 && splideIndex <= 3) {
			setTextInfo({
				title: MergeHeroesTitle,
				description: MergeHeroesDescription
			})
		} else if (splideIndex === 4) {
			setTextInfo({
				title: VirtualLandTitle,
				description: VirtualLandDescription
			})
		}
	}, [ splideIndex ])

	function mounted() {
		setSplideIndex(0)
	}

	function move(splide) {
		setSplideIndex(splide.index)
	}

	const SliderOptions = {
		type: "loop",
		autoplay: true
	}

	return (
		<BlankSection
			elementId="games-section"
			title="Our Games"
			content={
				<div id="games-text-slider">
					<div id="games-text" className="section-text">
						<p id="games-text-title" className="section-title">{ textInfo.title }</p>
						<p id="games-text-description" className="section-description">{ textInfo.description }</p>
					</div>
					<div id="games-slider-container">
						<Splide id="games-slider" options={ SliderOptions } onMounted={ mounted } onMove={ move }>
							{ OurGamesList() }
						</Splide>
						<img id="games-slider-border" src={ GenericBorder } alt="Our Games Border"/>
					</div>
				</div>
			}
		/>
	)
}

export default OurGames