import { useEffect, useState } from "react"
import { delay } from "../Utilities/Time"
import LogoIconLight from "../Assets/Images/Preloader/LogoIcon-Light.svg"
import LogoIconGlow from "../Assets/Images/Preloader/LogoIcon-Glow.svg"
import "../Assets/Styles/Preloader.scss"

function LogoIcons() {
	let [ isActive, setIsActive ] = useState(false)

	useEffect(function () {
		let animationInterval = setInterval(async function () {
			setIsActive(true)
			await delay(150)
			setIsActive(false)
			await delay(150)
			setIsActive(true)
			await delay(150)
			setIsActive(false)
			await delay(500)
			setIsActive(true)
			await delay(150)
			setIsActive(false)
			await delay(150)
			setIsActive(true)
			await delay(150)
			setIsActive(false)
		}, 3000)

		return function () {
			clearInterval(animationInterval)
		}
	}, [])

	return (
		<div id="preloader-logo-icons">
			<img className={
				isActive ?
					"preloader-logo-icon preloader-logo-icon-light preloader-logo-icon-light-active" :
					"preloader-logo-icon preloader-logo-icon-light"
			} src={ LogoIconLight } alt="Logo Icon 1"/>
			<img className={
				isActive ?
					"preloader-logo-icon preloader-logo-icon-glow preloader-logo-icon-glow-active" :
					"preloader-logo-icon preloader-logo-icon-glow"
			} src={ LogoIconGlow } alt="Logo Icon 2"/>
		</div>
	)
}

function LoadingText() {
	let [ text, setText ] = useState("Loading")

	useEffect(function () {
		let textInterval = setInterval(function () {
			function getNextText(currentText) {
				if (currentText.endsWith("...")) {
					return "Loading"
				} else {
					return currentText + "."
				}
			}

			setText(getNextText)
		}, 500)

		return function () {
			clearInterval(textInterval)
		}
	}, [])

	return (
		<p id="preloader-text">
			{ text }
		</p>
	)
}

function Preloader(props) {
	const FadingState = {
		none: 0,
		fading: 1,
		faded: 2
	}

	let [ fadingState, setFadingState ] = useState(FadingState.none)

	async function load() {
		await delay(1000)
		setFadingState(FadingState.fading)
		await delay(1000)
		setFadingState(FadingState.faded)
	}

	useEffect(function () {
		(async function () {
			if (document.readyState === "complete") {
				await load()
			} else {
				window.addEventListener("load", load)

				return function () {
					window.removeEventListener("load", load)
				}
			}
		})()
	}, [])

	useEffect(function () {
		props.onMount()
	}, [])

	function fadingClass() {
		let className = "preloader"

		if (fadingState === FadingState.fading) className += " preloader-fading"
		else if (fadingState === FadingState.faded) className += " preloader-faded"

		return className
	}

	return (
		<div className={ fadingClass() }>
			<div id="preloader-content">
				<LogoIcons/>
				<LoadingText/>
			</div>
		</div>
	)
}

export default Preloader