import "../../Assets/Styles/Aquarians/UtilityNFTs.scss"

function UtilityNFTs() {
	return (
		<div id="utility-nfts" className="card">
			<div className="aquarians-section-text">
				<p className="aquarians-section-title">Utility NFTs</p>
				<p className="aquarians-section-description">
					The longer you hold it in your wallet the bigger the reward. As our ecosystem grows, Aquarian owners will have benefits between digital and physical worlds. Such as private club membership and exclusive access to latest products and events.
				</p>
			</div>
		</div>
	)
}

export default UtilityNFTs