import { useState } from "react"
import "../../Assets/Styles/Mint/MintDialog.scss"

function MintDialog(props) {
	let [ quantity, setQuantity ] = useState(1)

	function decrementQuantity() {
		if (quantity > 1) setQuantity(quantity - 1)
	}

	function incrementQuantity() {
		if (quantity < props.limit) setQuantity(quantity + 1)
	}

	function maxQuantity() {
		setQuantity(props.limit)
	}

	function mint() {
		props.onMint(quantity)
	}

	return (
		<>
			<div id="price-container" className="paragraph">
				<p id="price-title" className="paragraph-text">
					Price per NFT:
				</p>
				<p id="price-value" className="paragraph-text">
					{ props.price } ETH
				</p>
			</div>
			<div id="address-container" className="paragraph">
				<p id="address-title" className="paragraph-text">
					Connected address:
				</p>
				<p id="address-value" className="paragraph-text">
					{ props.address }
				</p>
			</div>
			<div id="quantity-container">
				<div id="quantity">
					<button id="quantity-decrement" className="button button-thin" onClick={ decrementQuantity }>-
					</button>
					<input id="quantity-input" type="number" placeholder="Enter quantity" readOnly value={ quantity }/>
					<button id="quantity-decrement" className="button button-thin" onClick={ incrementQuantity }>+
					</button>
				</div>
				<button id="quantity-max" className="button button-thin" onClick={ maxQuantity }>
					MAX
				</button>
			</div>
			<button id="mint" className="button button-purple button-large" onClick={ mint }>
				Mint now
			</button>
		</>
	)
}

export default MintDialog