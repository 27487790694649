import Aquarian_1 from "../../../Assets/Images/Home/AquarianNFTCollection/Aquarian_1.png"
import Aquarian_2 from "../../../Assets/Images/Home/AquarianNFTCollection/Aquarian_2.png"
import Aquarian_3 from "../../../Assets/Images/Home/AquarianNFTCollection/Aquarian_3.png"
import Aquarian_4 from "../../../Assets/Images/Home/AquarianNFTCollection/Aquarian_4.png"
import Aquarian_5 from "../../../Assets/Images/Home/AquarianNFTCollection/Aquarian_5.png"
import Aquarian_6 from "../../../Assets/Images/Home/AquarianNFTCollection/Aquarian_6.png"
import Aquarian_7 from "../../../Assets/Images/Home/AquarianNFTCollection/Aquarian_7.png"
import Aquarian_8 from "../../../Assets/Images/Home/AquarianNFTCollection/Aquarian_8.png"
import Aquarian_9 from "../../../Assets/Images/Home/AquarianNFTCollection/Aquarian_9.png"
import Aquarian_10 from "../../../Assets/Images/Home/AquarianNFTCollection/Aquarian_10.png"
import Aquarian_11 from "../../../Assets/Images/Home/AquarianNFTCollection/Aquarian_11.png"
import Aquarian_12 from "../../../Assets/Images/Home/AquarianNFTCollection/Aquarian_12.png"
import Aquarian_13 from "../../../Assets/Images/Home/AquarianNFTCollection/Aquarian_13.png"
import Aquarian_14 from "../../../Assets/Images/Home/AquarianNFTCollection/Aquarian_14.png"
import Aquarian_15 from "../../../Assets/Images/Home/AquarianNFTCollection/Aquarian_15.png"
import Aquarian_16 from "../../../Assets/Images/Home/AquarianNFTCollection/Aquarian_16.png"
import BlankSection from "./BlankSection"
import "../../../Assets/Styles/Home/AquarianNFTCollection.scss"

function AquarianNFTCollection() {
	return (
		<BlankSection
			elementId="aquarian-section"
			content={
				<div id="aquarian-nft-collection">
					<div id="aquarian-nfts">
						<img className="aquarian-nft" src={ Aquarian_1 } alt="Aquarian NFT"/>
						<img className="aquarian-nft" src={ Aquarian_2 } alt="Aquarian NFT"/>
						<img className="aquarian-nft" src={ Aquarian_3 } alt="Aquarian NFT"/>
						<img className="aquarian-nft" src={ Aquarian_4 } alt="Aquarian NFT"/>
						<img className="aquarian-nft" src={ Aquarian_5 } alt="Aquarian NFT"/>
						<img className="aquarian-nft" src={ Aquarian_6 } alt="Aquarian NFT"/>
						<img className="aquarian-nft" src={ Aquarian_7 } alt="Aquarian NFT"/>
						<img className="aquarian-nft" src={ Aquarian_8 } alt="Aquarian NFT"/>
						<img className="aquarian-nft" src={ Aquarian_9 } alt="Aquarian NFT"/>
						<img className="aquarian-nft" src={ Aquarian_10 } alt="Aquarian NFT"/>
						<img className="aquarian-nft" src={ Aquarian_11 } alt="Aquarian NFT"/>
						<img className="aquarian-nft" src={ Aquarian_12 } alt="Aquarian NFT"/>
						<img className="aquarian-nft" src={ Aquarian_13 } alt="Aquarian NFT"/>
						<img className="aquarian-nft" src={ Aquarian_14 } alt="Aquarian NFT"/>
						<img className="aquarian-nft" src={ Aquarian_15 } alt="Aquarian NFT"/>
						<img className="aquarian-nft" src={ Aquarian_16 } alt="Aquarian NFT"/>
					</div>
					<div id="aquarian-nft-collection-text" className="section-text">
						<p id="aquarian-nft-collection-title" className="section-title">Aquarian NFT Collection</p>
						<p id="aquarian-nft-collection-description" className="section-description">
							Aquarians are 10,000 uniquely generated alien avatars. They come with different rarity powered attributes. Holding an Aquarian is the way to a successful conquest.
						</p>
						<div id="aquarian-nft-collection-buttons">
							<a className="button button-large" href="/aquarians" target="_blank">Learn more</a>
							<a className="button button-purple button-large" href="/mint/aquarians">Mint now</a>
						</div>
					</div>
				</div>
			}
		/>
	)
}

export default AquarianNFTCollection