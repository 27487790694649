function showSubscribeResponse(response) {
	if (response.success) {
		alert("Success! " + response.message)
	} else {
		alert("Error! " + response.message)
	}
}

async function subscribeCategory(email, categories) {
	let requestData = new FormData()
	requestData.append("email", email)

	for (let category of categories) {
		requestData.append(category, "");
	}

	try {
		let response = await fetch("/api/subscribe", {
				method: "POST",
				headers: {
					"Accept": "application/json"
				},
				body: requestData
			}
		)

		return await response.json()
	} catch (error) {
		return {
			"success": false,
			"message": error.message
		}
	}
}

export { subscribeCategory, showSubscribeResponse }