import { isIOS, isSafari } from "react-device-detect"
import TheCuriousWitch_Animated from "../../../Assets/Images/Home/MembershipNFTs/Animated/TheCuriousWitch.webm"
import TheDreamFairy_Animated from "../../../Assets/Images/Home/MembershipNFTs/Animated/TheDreamFairy.webm"
import TheEnchantedDragon_Animated from "../../../Assets/Images/Home/MembershipNFTs/Animated/TheEnchantedDragon.webm"
import TheGuardian_Animated from "../../../Assets/Images/Home/MembershipNFTs/Animated/TheGuardian.webm"
import TheLightOfFortune_Animated from "../../../Assets/Images/Home/MembershipNFTs/Animated/TheLightOfFortune.webm"
import TheMoumer_Animated from "../../../Assets/Images/Home/MembershipNFTs/Animated/TheMoumer.webm"
import TheRiseOfTheGiants_Animated from "../../../Assets/Images/Home/MembershipNFTs/Animated/TheRiseOfTheGiants.webm"
import TheStoryteller_Animated from "../../../Assets/Images/Home/MembershipNFTs/Animated/TheStoryteller.webm"
import TheThreeWitches_Animated from "../../../Assets/Images/Home/MembershipNFTs/Animated/TheThreeWitches.webm"
import WhenTheThunderGlowsBlue_Animated from "../../../Assets/Images/Home/MembershipNFTs/Animated/WhenTheThunderGlowsBlue.webm"
import TheCuriousWitch_Static from "../../../Assets/Images/Home/MembershipNFTs/Static/TheCuriousWitch.png"
import TheDreamFairy_Static from "../../../Assets/Images/Home/MembershipNFTs/Static/TheDreamFairy.png"
import TheEnchantedDragon_Static from "../../../Assets/Images/Home/MembershipNFTs/Static/TheEnchantedDragon.png"
import TheGuardian_Static from "../../../Assets/Images/Home/MembershipNFTs/Static/TheGuardian.png"
import TheLightOfFortune_Static from "../../../Assets/Images/Home/MembershipNFTs/Static/TheLightOfFortune.png"
import TheMoumer_Static from "../../../Assets/Images/Home/MembershipNFTs/Static/TheMoumer.png"
import TheRiseOfTheGiants_Static from "../../../Assets/Images/Home/MembershipNFTs/Static/TheRiseOfTheGiants.png"
import TheStoryteller_Static from "../../../Assets/Images/Home/MembershipNFTs/Static/TheStoryteller.png"
import TheThreeWitches_Static from "../../../Assets/Images/Home/MembershipNFTs/Static/TheThreeWitches.png"
import WhenTheThunderGlowsBlue_Static from "../../../Assets/Images/Home/MembershipNFTs/Static/WhenTheThunderGlowsBlue.png"
import BlankSection from "./BlankSection"
import "../../../Assets/Styles/Home/MergeHeroesNFTs.scss"

function MergeHeroesNFT(props) {
	function isAppleDevice() {
		return isIOS || isSafari
	}

	return (
		isAppleDevice() ?
			<img className="merge-heroes-nft" src={ props.staticImage } alt="Merge Heroes NFT"/> :
			<video className="merge-heroes-nft" autoPlay loop muted playsInline src={ props.animatedImage }/>
	)
}

function MergeHeroesNFTs() {
	return (
		<BlankSection
			elementId="merge-heroes-nfts-section"
			title="Merge Heroes NFTs"
			content={
				<div id="merge-heroes-nfts">
					<MergeHeroesNFT staticImage={ TheCuriousWitch_Static } animatedImage={ TheCuriousWitch_Animated }/>
					<MergeHeroesNFT staticImage={ TheDreamFairy_Static } animatedImage={ TheDreamFairy_Animated }/>
					<MergeHeroesNFT staticImage={ TheGuardian_Static } animatedImage={ TheGuardian_Animated }/>
					<MergeHeroesNFT staticImage={ TheLightOfFortune_Static } animatedImage={ TheLightOfFortune_Animated }/>
					<MergeHeroesNFT staticImage={ TheMoumer_Static } animatedImage={ TheMoumer_Animated }/>
					<MergeHeroesNFT staticImage={ TheRiseOfTheGiants_Static } animatedImage={ TheRiseOfTheGiants_Animated }/>
					<MergeHeroesNFT staticImage={ TheStoryteller_Static } animatedImage={ TheStoryteller_Animated }/>
					<MergeHeroesNFT staticImage={ TheThreeWitches_Static } animatedImage={ TheThreeWitches_Animated }/>
					<MergeHeroesNFT staticImage={ TheEnchantedDragon_Static } animatedImage={ TheEnchantedDragon_Animated }/>
					<MergeHeroesNFT staticImage={ WhenTheThunderGlowsBlue_Static } animatedImage={ WhenTheThunderGlowsBlue_Animated }/>
				</div>
			}
		/>
	)
}

export default MergeHeroesNFTs