import Header from "../Components/Header"
import "../Assets/Styles/NotFound.scss"

function NotFound() {
	return (
		<div>
			<Header/>
			<div id="message">
				<p id="message-title">404</p>
				<p id="message-description">Page Not Found</p>
				<p id="message-details">The page you are looking for does not exist.</p>
			</div>
		</div>
	)
}

export default NotFound